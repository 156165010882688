import {
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-itg-map-pointer",
  templateUrl: "./itg-map-pointer.component.html",
  styleUrls: ["./itg-map-pointer.component.scss"],
})
export class ItgMapPointerComponent implements OnInit {
  @ViewChild("setLocationDialog", { static: false })
  setLocationDialog: TemplateRef<any>;
  @Input() public latitiude = 0;
  @Input() public longitude = 0;
  @Input() public btntext = "Update";
  @Input() public editable = false;
  @Output("onSelect") onSelect: EventEmitter<any> = new EventEmitter();
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void { }

  openAddress(): void {
    this.dialog.open(this.setLocationDialog, {
      width: '900px',
      height: '500px',
      disableClose: true
    });
  }

  onNoClick() {
    this.dialog.closeAll();
  }

  gotLocation(event) {
    // console.log(event);
    this.latitiude = event.lat;
    this.longitude = event.lng;
  }

  updateLocation() {
    const position = {
      lat: this.latitiude.toString(),
      lng: this.longitude.toString(),
    };
    this.onSelect.emit(position);
    this.onNoClick();
  }
}
