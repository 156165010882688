<div class="d-flex align-items-center">
    <div>
        <ng-container *ngIf="!searchBoxDisabled">
            <form #l="ngForm" (ngSubmit)="onSubmit(l)" autocomplete="off">
                <div class="d-flex align-items-center">
                    <div class="form-group pointer">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="{{placeholder}}"
                                [(ngModel)]="search.text" name="searchText" required (input)="doInput()">
                            <div class="input-group-append clear" (click)="doClear()" *ngIf="search.text !== ''">
                                <span class="input-group-text d-flex align-items-center">
                                    <ion-icon name="close-outline"></ion-icon>
                                </span>
                            </div>
                            <div class="input-group-append z-2">
                                <ion-icon name="search-outline"></ion-icon>
                            </div>
                            <!-- <div class="input-group-append" (click)="isOpen = !isOpen">
                                <span class="input-group-text d-flex align-items-center">
                                    <ion-icon name="chevron-down-outline"></ion-icon>
                                </span>
                            </div> -->
                        </div>
                    </div>
                    <button type="submit" mat-raised-button color="accent" class="ml-1">
                        Search
                    </button>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="searchBoxDisabled">
            <div class="form-group pointer" (click)="attachOverlay()" matRipple #formGroup>
                <div class="input-group">
                    <div type="text" class="form-control form-control-div align-items-center pl-3"><span
                            class="placeholder">{{placeholder}}</span></div>
                            <div class="input-group-append z-2">
                                <ion-icon name="search-outline"></ion-icon>
                            </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>