import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { DeleteAlertComponent } from '../../alerts/delete-alert/delete-alert.component';

@Component({
  selector: 'app-table-control',
  templateUrl: './table-control.component.html',
  styleUrls: ['./table-control.component.scss'],
})
export class TableControlComponent implements OnInit {
  @Input() tableData = {
    header: [],
    data: [],
    options: {},
  };
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Output("onDelete") onDelete: EventEmitter<any> = new EventEmitter();
  @Output("onEdit") onEdit: EventEmitter<any> = new EventEmitter();
  @Output("onView") onView: EventEmitter<any> = new EventEmitter();
  @Output("onSort") onSort: EventEmitter<any> = new EventEmitter();
  loadingTrue = false;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    this.loadingTrue = true;
    setTimeout(() => {
      this.loadingTrue = false;
    }, 400);
  }

  openDelete(val): void {
    const dialogRef = this.dialog.open(DeleteAlertComponent, {
      width: "350px",
      data: {
        msg: "Are you sure, you want to delete this record ?",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.doDelete(val);
      }
    });
  }

  doDelete(val) {
    this.onDelete.next(val);
  }

  doEdit(val) {
    this.onEdit.next(val);
    // console.log(val);
  }

  doView(val) {
    this.onView.next(val);
    // console.log(val);
  }
  sortData(event) {
    this.onSort.next(event);
    // console.log(event);
  }
}
