import { Component } from '@angular/core';
import { ConnectionService } from './common/services/connection/connection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'requestTracker-web';
  constructor(public connection: ConnectionService) {}
  ngOnInit(): void {
    this.connection.networkStatus();
  }
}
