import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FullSpinnerInterceptor } from './common/full-spinner/full-spinner.interceptor';
import { AppSettingsService } from './common/services/app-settings/app-settings.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { IonicStorageModule } from '@ionic/storage';
import { SnackbarModule } from './common/alerts/snackbar/snackbar.module';
import { AppConfig } from './common/config/app.config';
import { EmptyStateModule } from './common/empty-state/empty-state.module';
import { FormInputModule } from './common/form-input/form-input.module';
import { ShareModule } from './common/share/share.module';
import { OtpValidationComponent } from './initial/otp-validation/otp-validation.component';
export const appSettingFactory = (configService: AppSettingsService) => {
  return () => configService.loadConfig();
};
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ShareModule,
    IonicStorageModule.forRoot({
      name: AppConfig.dbname,
      driverOrder: ['sqlite', 'indexeddb', 'localstorage'],
    }),
    SnackbarModule,
    FormInputModule,
    EmptyStateModule,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FullSpinnerInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appSettingFactory,
      deps: [AppSettingsService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { } 
