import { OverlayRef } from '@angular/cdk/overlay';
import {
    ElementRef,
    Injectable,
    Renderer2,
    RendererFactory2,
} from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SearchBoxOverlayRef {
    overlayRef!: OverlayRef;
    activeElementRef!: ElementRef;
    currentElementRef!: ElementRef;
    isOpenNow: boolean = false;
    private renderer: Renderer2;
    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public detachOverlay(): void {
        // console.log(this.overlayRef);
        if (this.overlayRef) {
            if (this.overlayRef.hasAttached()) {
                this.overlayRef.detach();
                this.isOpenNow = false;
                // this.renderer.removeClass(this.activeElementRef.nativeElement, 'active');
            }
        }
    }
}
