import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { FormInputModule } from '../form-input/form-input.module';
import { MatTableModule } from '@angular/material/table';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmptyStateModule } from '../empty-state/empty-state.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { FullSpinnerModule } from '../full-spinner/full-spinner.module';
import { CommonAlertModule } from '../alerts/common-alert/common-alert.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { SlideDialogModule } from '../slide-dialog/slide-dialog.module';
// import { PermissionModule } from '../permission/permission.module';
import { SessionAlertModule } from '../alerts/session-alert/session-alert.module';
import { SearchBoxModule } from '../search-box/search-box.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { AddressControlModule } from '../form-input/address-control/address-control.module';
import { ItgMapModule } from '../itg-map/itg-map.module';
import { PermissionModule } from '../permission/permission.module';
import { MatSortModule } from '@angular/material/sort';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EmptyStateModule,
    SearchBoxModule,
    CommonAlertModule,
    SpinnerModule,
    DirectivesModule,
    PipesModule,
    MatButtonModule,
    FormInputModule,
    OverlayModule,
    MatPaginatorModule,
    MatTooltipModule,
    FullSpinnerModule,
    MatTabsModule,
    MatCheckboxModule,
    MatListModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatRippleModule,
    SlideDialogModule,
    PermissionModule,
    SessionAlertModule,
    MatMenuModule,
    ItgMapModule,
    MatSortModule,
  ],
  exports: [
    EmptyStateModule,
    SearchBoxModule,
    CommonAlertModule,
    SpinnerModule,
    DirectivesModule,
    PipesModule,
    MatButtonModule,
    FormInputModule,
    MatTableModule,
    OverlayModule,
    MatPaginatorModule,
    MatTooltipModule,
    FullSpinnerModule,
    MatTabsModule,
    MatCheckboxModule,
    MatListModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatRippleModule,
    SlideDialogModule,
    PermissionModule,
    SessionAlertModule,
    MatMenuModule,
    ItgMapModule,
    MatSortModule
  ],
})
export class ShareModule { }
