import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './common/services/auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./initial/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'itg-login',
    loadChildren: () =>
      import('./initial/itg-login/itg-login.module').then(
        (m) => m.ItgLoginModule
      ),
  },
  {
    path: 'temp-login/:id',
    loadChildren: () =>
      import('./initial/temp-login/temp-login.module').then(
        (m) => m.TempLoginModule
      ),
  },
  {
    path: 'first-time-login',
    loadChildren: () =>
      import('./initial/first-time-login/first-time-login.module').then(
        (m) => m.FirstTimeLoginModule
      ),
  },
  {
    path: 'trouble-to-start/:id',
    loadChildren: () =>
      import('./initial/trouble-start/trouble-start.module').then(
        (m) => m.TroubleStartModule
      ),
  },
  {
    path: 'forgot-username/:id',
    loadChildren: () =>
      import('./initial/forgot-username/forgot-username.module').then(
        (m) => m.ForgotUsernameModule
      ),
  },
  {
    path: 'forgot-password/:id',
    loadChildren: () =>
      import('./initial/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'otp-validation',
    loadChildren: () =>
      import(
        './initial/otp-validation/otp-validation.module'
      ).then((m) => m.OtpValidationModule),
  },
  {
    path: 'reset-password/:id',
    loadChildren: () =>
      import('./initial/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
