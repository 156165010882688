<div class="">
    <div mat-dialog-title class="text-center text-danger my-2">
        <div class="d-flex align-items-center justify-content-center">
            <ion-icon src="assets/svg/triangle.svg"></ion-icon>
            <div class="ml-2">Alert</div>
        </div>

    </div>
    <div mat-dialog-content class="container-b text-center">
        <p class="tc">{{data.msg}}</p>
    </div>
    <div class="pt-3"></div> 
    <div mat-dialog-actions class="d-flex align-items-center justify-content-center">
        <ng-container *ngIf="data.showFalse">
            <ng-container *ngIf="data.falseRaised">
                <button mat-raised-button (click)="valueClicked(false)"
                    [color]="data.falseBtnColor">{{data.falseBtnText}}</button>
            </ng-container>
            <ng-container *ngIf="!data.falseRaised">
                <button mat-button (click)="valueClicked(false)"
                    [color]="data.falseBtnColor">{{data.falseBtnText}}</button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="data.showTrue">
            <ng-container *ngIf="data.trueRaised">
                <button mat-raised-button (click)="valueClicked(true)"
                    [color]="data.trueBtnColor">{{data.trueBtnText}}</button>
            </ng-container>
            <ng-container *ngIf="!data.trueRaised">
                <button mat-button (click)="valueClicked(true)"
                    [color]="data.trueBtnColor">{{data.trueBtnText}}</button>
            </ng-container>
        </ng-container>
    </div>
</div>