<ng-container *ngIf="valuetype === 'string'">
    <div class="form-group">
        <div class="d-flex align-item-center h20" *ngIf="label !== ''">
            <label *ngIf="label !== ''" class="lh15 pb-1">{{label}} <span class="text-danger"
                    *ngIf="mandatory">*</span></label>
            <div class="ml-auto text-danger lh15 pb-1" *ngIf="value === '' && mandatory && errorTrueNow">
                <small>mandatory</small>
            </div>
        </div>
        <!-- <div class="input-group"> -->
        <mat-select class="form-control pr-1" [(ngModel)]="value"
            [ngClass]="{'border-danger': (value === '') && errorTrueNow}" [disabled]="btnClickedNow" [name]="name"
            (selectionChange)="doChange($event)">
            <mat-option value=''>
                --select--
            </mat-option>
            <mat-option *ngIf="search">
                <ngx-mat-select-search [noEntriesFoundLabel]="'No Data Found'" [placeholderLabel]="placeholderLabel"
                    [(ngModel)]="searchItem" name="searchitem" type="input">
                    <ion-icon class="select-icon" ngxMatSelectSearchClear name="close-outline"></ion-icon>
                    <!-- <mat-icon  class="i-btn">close</mat-icon> -->
                </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of items" [value]="item[key]">
                {{item[keyname]}}
            </mat-option>
        </mat-select>

    </div>
</ng-container>

<ng-container *ngIf="valuetype === 'int'">
    <div class="form-group">
        <div class="d-flex align-item-center pb-1">
            <label *ngIf="label !== ''" class="lh15">{{label}} <span class="text-danger"
                    *ngIf="mandatory">*</span></label>
            <div class="ml-auto text-danger lh15" *ngIf="value === 0 && mandatory && errorTrueNow">
                <small>mandatory</small>
            </div>
        </div>
        <!-- <div class="input-group"> -->
        <mat-select class="form-control pr-1" [(ngModel)]="value"
            [ngClass]="{'border-danger': (value === 0) && errorTrueNow}" [disabled]="btnClickedNow" [name]="name"
            (selectionChange)="doChange($event)">
            <mat-option [value]="valnum">
                --select--
            </mat-option>
            <mat-option *ngIf="search">
                <ngx-mat-select-search [noEntriesFoundLabel]="'No Data Found'" [placeholderLabel]="placeholderLabel"
                    [(ngModel)]="searchItem" name="searchitem" type="input">
                    <ion-icon class="select-icon" ngxMatSelectSearchClear name="close-outline"></ion-icon>
                    <!-- <mat-icon  class="i-btn">close</mat-icon> -->
                </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of items" [value]="item[key]">
                {{item[keyname]}}
            </mat-option>
        </mat-select>

    </div>
</ng-container>