import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { SearchBoxOverlayRef } from "./search-box.service";

@Component({
  selector: "app-search-box",
  templateUrl: "./search-box.component.html",
  styleUrls: ["./search-box.component.scss"],
})
export class SearchBoxComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() searchBoxDisabled = true;
  // @Input() isOpen = false;
  @Input() searchTemplate: TemplateRef<any>;
  @Input() public placeholder: string = "Search";
  @Input() public defaultSearch = "";
  @Input() public searchParams: any;
  @Output("onSearch") onSearch: EventEmitter<any> = new EventEmitter();

  // isOpenNow = false;
  @Input()
  set isOpen(isOpen: boolean) {
    this.searchBoxOverlayRef.isOpenNow = isOpen || false;
    // console.log(this.isOpenNow);
  }
  get isOpen() {
    return this.searchBoxOverlayRef.isOpenNow;
  }

  search = {
    text: "",
  };
  currentTemplate: TemplateRef<any>;

  @ViewChild("formGroup", { static: false }) formGroup: ElementRef;
  // private overlayRef!: OverlayRef;

  constructor(private overlay: Overlay, private vcr: ViewContainerRef, public searchBoxOverlayRef: SearchBoxOverlayRef) { }

  ngOnInit(): void { }
  ngAfterViewInit() {
    this.createOverlay();
  }
  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
  }

  doClear() {
    this.search.text = "";
    this.searchParams[this.defaultSearch] = this.search.text;
    this.onSearch.next(this.searchParams);
  }
  doClose() {
    // if (this.isOpen) {
    //   this.isOpen = false;
    // }
    // console.log(this.isOpen);
    // this.isOpen = false;
    if (!this.isOpen) {
      this.searchBoxOverlayRef.detachOverlay();
      // this.isOpen = false;
    }
  }

  onSubmit(l) {
    if (l.valid) {
      this.searchParams[this.defaultSearch] = this.search.text;
      this.onSearch.next(this.searchParams);
    }
  }
  doInput() {
    if (this.search.text.length === 0) {
      this.searchParams[this.defaultSearch] = this.search.text;
      this.onSearch.next(this.searchParams);
    }
  }

  createOverlay() {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.formGroup.nativeElement)
      .withPositions([
        new ConnectionPositionPair(
          { originX: "end", originY: "bottom" },
          { overlayX: "end", overlayY: "top" }
        ),
        new ConnectionPositionPair(
          { originX: "end", originY: "top" },
          { overlayX: "end", overlayY: "bottom" }
        ),
      ])
      .withPush(false);
    this.searchBoxOverlayRef.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: "bg-transparent",
    });
    this.searchBoxOverlayRef.overlayRef.backdropClick().subscribe((_) => this.searchBoxOverlayRef.detachOverlay());
  }

  attachOverlay(): void {
    // this.isOpen = true;
    if (!this.searchBoxOverlayRef.overlayRef.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(
        this.searchTemplate,
        this.vcr
      );

      this.searchBoxOverlayRef.overlayRef.attach(periodSelectorPortal);
    }
  }

  // private detachOverlay(): void {
  //   // this.isOpen = false;
  //   if (this.overlayRef.hasAttached()) {
  //     this.overlayRef.detach();
  //   }
  // }
  // private detachOverlay(): void {
  //   // this.isOpen = false;
  //   if (this.searBoxOverlayRef.overlayRef.hasAttached()) {
  //     this.searBoxOverlayRef.overlayRef.detach();
  //     this.isOpen = false;
  //   }
  // }
}
