import { environment } from 'src/environments/environment';

export class AppConfig {
  public static roleEnabled = false;
  public static routeGuardEnabled = false;
  public static userDataStorageName = '';
  public static baseCurrency = 'VUV';
  public static dbname = '__requestTrackerApp';
  public static dateServerFormat = 'yyyy-mm-dd';
  public static dateViewFormat = 'dd MMM yyyy';
  public static pageSizeOptions = [10, 20, 50, 100];
  public static EmailPattern = '^S+@S+$';
}
