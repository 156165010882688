import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItgPlaceSearchComponent } from "./itg-place-search/itg-place-search.component";
import { ItgMapViewComponent } from "./itg-map-view/itg-map-view.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { ItgMapPointerComponent } from "./itg-map-pointer/itg-map-pointer.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
  declarations: [
    ItgPlaceSearchComponent,
    ItgMapViewComponent,
    ItgMapPointerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  exports: [
    ItgPlaceSearchComponent,
    ItgMapViewComponent,
    ItgMapPointerComponent,
  ],
})
export class ItgMapModule { }
