<a mat-icon-button class="btn-location" (click)="openAddress()">
    <ion-icon name="location"></ion-icon>
</a>

<ng-template #setLocationDialog>
    <div class="vertical-flex h100">
        <div class="dialog-header">
            <h6 mat-dialog-title>
                <ng-container *ngIf="editable">
                    <div>Set Location</div>
                </ng-container>
                <ng-container *ngIf="!editable">
                    <div>View Location</div>
                </ng-container>
            </h6>

            <div class="ml-auto">
                <ng-container *ngIf="editable">
                    <button mat-raised-button color="primary" (click)="updateLocation()">{{btntext}}</button>
                </ng-container>
                <a mat-icon-button (click)="onNoClick()" class="ml-3">
                    <ion-icon name="close-outline"></ion-icon>
                </a>
            </div>

        </div>
        <div mat-dialog-content class="flex-1 h100">
            <div class="vertical-flex h100">
                <div class="flex-1 relative">
                    <div class="ab">
                        <div class="rel">
                            <div class="p-3">
                                <ng-container *ngIf="editable">
                                    <app-itg-place-search latitude]="latitiude" [longitude]="longitude"
                                        (onSelect)="gotLocation($event)"></app-itg-place-search>
                                </ng-container>
                                <div class="map-box">
                                    <div class="map-container">
                                        <app-itg-map-view [latitude]="latitiude" [longitude]="longitude"
                                            (onSelect)="gotLocation($event)" [draggable]="editable">
                                        </app-itg-map-view>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>