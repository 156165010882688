import { AfterViewInit, EventEmitter, Output } from "@angular/core";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
declare const google: any;

@Component({
  selector: "app-itg-place-search",
  templateUrl: "./itg-place-search.component.html",
  styleUrls: ["./itg-place-search.component.scss"],
})
export class ItgPlaceSearchComponent implements OnInit, AfterViewInit {
  @Input() public placeholder: string = "Search your place";
  autocomplete: ElementRef;
  @ViewChild("autocomplete", { static: false }) set content1(
    content1: ElementRef
  ) {
    this.autocomplete = content1;
  }
  @Output("onSelect") onSelect: EventEmitter<any> = new EventEmitter();
  latitudeNow = 0;
  @Input()
  set latitude(latitude: any) {
    this.latitudeNow = parseFloat(latitude) || 0;
  }
  get latitude() {
    return this.latitudeNow;
  }
  longitudeNow = 0;
  @Input()
  set longitude(longitude: any) {
    this.longitudeNow = parseFloat(longitude) || 0;
  }
  get longitude() {
    return this.longitudeNow;
  }
  constructor(public snackbar: MatSnackBar) {}
  ngAfterViewInit(): void {
    // this.getCurrentLocation();
    setTimeout(() => {
      if (this.latitudeNow === 0 && this.longitudeNow === 0) {
        this.getCurrentLocation();
      }
      this.initMap();
    }, 400);
  }

  ngOnInit(): void {}

  initMap() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete.nativeElement
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      this.setPlaces(place);
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
    });
  }

  setPlaces(val) {
    const location = {
      lat: val.geometry.location.lat().toString(),
      lng: val.geometry.location.lng().toString(),
    };
    // console.log(location);
    this.onSelect.emit(location);
  }
  getCurrentLocation() {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "denied") {
        this.alertMessage(
          "Location access is blocked by the user. Enable Location access browser settings and try again"
        );
      }
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        const location = {
          lat: position.coords.latitude.toString(),
          lng: position.coords.longitude.toString(),
        };
        // const latitude = position.coords.latitude;
        // const longitude = position.coords.longitude;
        // console.log(this);

        this.onSelect.emit(location);
      });
    } else {
      this.alertMessage("Location access is not available in this system.");
    }
  }

  alertMessage(msg) {
    this.snackbar.open(msg, "Close", {
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ["bg-danger"],
      duration: 5000,
    });
  }
}
