import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatModule } from './date-control/date-format.module';
import { FormsModule } from '@angular/forms';
import { InputControlComponent } from './input-control/input-control.component';
import { PasswordControlComponent } from './password-control/password-control.component';
import { SelectControlComponent } from './select-control/select-control.component';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TableControlModule } from './table-control/table-control.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { EmailControlComponent } from './email-control/email-control.component';
import { NumberControlComponent } from './number-control/number-control.component';
import { TextareaControlComponent } from './textarea-control/textarea-control.component';
import { PinControlComponent } from './pin-control/pin-control.component';
import { DirectivesModule } from '../directives/directives.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormPipeModule } from './form-pipe/form-pipe.module';
// import { TimePickerModule } from "./time-picker/time-picker.module";

@NgModule({
  declarations: [
    InputControlComponent,
    PasswordControlComponent,
    SelectControlComponent,
    EmailControlComponent,
    NumberControlComponent,
    TextareaControlComponent,
    PinControlComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    TableControlModule,
    FormsModule,
    DateFormatModule,
    MatSelectModule,
    MatButtonModule,
    NgxMatSelectSearchModule,
    DirectivesModule,
    MatTooltipModule,
    OverlayModule,
    MatPasswordStrengthModule,
    FormPipeModule
    //TimePickerModule
  ],
  exports: [
    FormsModule,
    TableControlModule,
    DateFormatModule,
    InputControlComponent,
    PasswordControlComponent,
    SelectControlComponent,
    NgxMatSelectSearchModule,
    EmailControlComponent,
    NumberControlComponent,
    TextareaControlComponent,
    PinControlComponent,
    //TimePickerModule
  ],
})
export class FormInputModule {}
