import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AutofocusDirective } from "./autofocus/autofocus.directive";
import { LowerCaseDirective } from "./lower-case/lower-case.directive";
import { UpperCaseDirective } from "./upper-case/upper-case.directive";
import { NoEmojiDirective } from "./no-emoji/no-emoji.directive";
import { PinNumberOnlyDirective } from "./pin-number-only/pin-number-only.directive";
import { NumberOnlyDirective } from "./number-only/number-only.directive";
import { SearchPanelHeightDirective } from "./search-panel-height/search-panel-height.directive";

@NgModule({
  declarations: [
    AutofocusDirective,
    LowerCaseDirective,
    UpperCaseDirective,
    NoEmojiDirective,
    PinNumberOnlyDirective,
    NumberOnlyDirective,
    SearchPanelHeightDirective,
  ],
  imports: [CommonModule],
  exports: [
    AutofocusDirective,
    LowerCaseDirective,
    UpperCaseDirective,
    NoEmojiDirective,
    PinNumberOnlyDirective,
    NumberOnlyDirective,
    SearchPanelHeightDirective,
  ],
})
export class DirectivesModule {}
