import {
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  InjectionToken,
  NgModule,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { DateControlComponent } from "./date-control.component";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { CustomDateAdapter } from "./custom-date-adapter";
import { Platform, PlatformModule } from "@angular/cdk/platform";
import { AppSettingsService } from "../../services/app-settings/app-settings.service";

export const DATE_CONFIG = new InjectionToken("Date Format", {
  factory: () => {
    return {
      viewFormat: inject(AppSettingsService).environment.dateViewFormat,
    };
  },
});

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/dd/yyyy hh:mm A",
  },
  display: {
    dateInput: "dd MMM yyyy",
    monthYearLabel: "MMM yyyy",
    dateA11yLabel: "dd MMM yyyy",
    monthYearA11yLabel: "MMMM yyyy",
  },
};

@NgModule({
  declarations: [DateControlComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatButtonModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  exports: [DateControlComponent, MatDatepickerModule],
})
export class DateFormatModule { }
