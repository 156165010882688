<div>
  <div mat-dialog-title class="text-center text-danger">
    <div class="d-flex align-items-center justify-content-center pt-3">
      <ion-icon src="assets/svg/triangle.svg"></ion-icon>
      <div class="ml-2">Alert</div>
    </div>
  </div>
  <div mat-dialog-content class="container-b text-center">
    <p class="tc lh25">{{data.msg}}</p>
  </div>
  <div mat-dialog-actions class="d-flex align-items-center justify-content-center">
    <button mat-raised-button (click)="onNoClick()" color="danger" cdkFocusInitial>No</button>
    <ng-container *ngIf="!data.Otherbtn">
      <button mat-button (click)="close()" color="medium">Yes</button>
    </ng-container>
    <ng-container *ngIf="data.Otherbtn">
      <button mat-button (click)="close()" color="medium">{{data.otherbtntext}}</button>
    </ng-container>
  </div>
</div>