<div class="form-group">
    <div class="input-group">
        <input type="text" class="form-control" #autocomplete [placeholder]="placeholder">
        <div class="input-group-append d-flex in-1">
            <a mat-button tabIndex="-1" class="i-btn" matTooltip="Get Current Location" matTooltipPosition="right"
                (click)="getCurrentLocation()">
                <ion-icon name="locate"></ion-icon>
            </a>
        </div>
    </div>

</div>