<div class="form-group" *ngIf="icononly === 'false'">
    <div class="d-flex align-item-center h20">
        <label for="" *ngIf="label !== ''" class="lh15 pb-1">{{label}} <span class="text-danger"
                *ngIf="mandatory">*</span></label>
        <div class="ml-auto text-danger lh15 pb-1" *ngIf="errorText !== ''">
            <small>{{errorText}}</small>
        </div>
        <div class="ml-auto text-danger lh15 pb-1" *ngIf="value === '' && mandatory && errorTrueNow">
            <small>mandatory</small>
        </div>
        <a mat-icon-button class="i-btn-small ml-auto text-center" *ngIf="helptext" [matTooltip]="helptext">
            <!-- <mat-icon class="f16">help_outline</mat-icon> -->
            <ion-icon name="help-circle-outline"></ion-icon>
        </a>
    </div>
    <!-- <label for="" *ngIf="label !== ''" >{{label}}</label> -->
    <div class="input-group">
        <input appNoEmoji autocomplete="none" type="text" class="form-control" [(ngModel)]="value" name="{{name}}"
            placeholder="{{placeholder}}" [ngClass]="{'border-warn': value === '' && errorTrueNow}"
            [disabled]="btnClickedNow" (blur)="blured()" #inputbox (keydown)="checkenter($event)" #selectInput>
        <div class="input-group-append d-flex in-1" *ngIf="value !== '' && !btnClickedNow">
            <ng-container *ngIf="search === 'true'">
                <a mat-button class="i-btn" (click)="onSearch()" matTooltip="Search" matTooltipPosition="right"
                    tabIndex="-1">
                    <ion-icon name="search-outline"></ion-icon>
                </a>
            </ng-container>
            <ng-container *ngIf="value">
                <a mat-button class="i-btn" (click)="doClear()" matTooltip="Clear" matTooltipPosition="right"
                    tabIndex="-1">
                    <!-- <mat-icon>clear</mat-icon> -->
                    <ion-icon name="close-outline"></ion-icon>
                </a>
            </ng-container>
        </div>
    </div>
</div>

<div class="form-group" *ngIf="icononly === 'true'">
    <div class="input-group">
        <input appNoEmoji autocomplete="none" type="text" class="form-control" [(ngModel)]="value" name="{{name}}"
            placeholder="{{placeholder}}" [ngClass]="{'border-warn': value === '' && errorTrueNow}"
            [disabled]="btnClickedNow">
        <div class="input-group-append d-flex in-1" *ngIf="value !== '' && !btnClickedNow">
            <ng-container *ngIf="search === 'true'">
                <a mat-button class="i-btn" (click)="onSearch()" matTooltip="Search" matTooltipPosition="right"
                    tabIndex="-1">
                    <ion-icon name="search-outline"></ion-icon>
                </a>
            </ng-container>
            <a mat-button class="i-btn" (click)="value = ''" matTooltip="Clear" matTooltipPosition="right"
                tabIndex="-1">
                <!-- <mat-icon>clear</mat-icon> -->
                <ion-icon name="close-outline"></ion-icon>
            </a>
        </div>
    </div>
</div>