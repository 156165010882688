import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as L from "leaflet";

@Component({
  selector: "app-itg-map-view",
  templateUrl: "./itg-map-view.component.html",
  styleUrls: ["./itg-map-view.component.scss"],
})
export class ItgMapViewComponent implements OnInit {
  private map: any;
  private marker: any;
  @Input() public draggable = true;

  latitudeNow = 0;
  @Input()
  set latitude(latitude: any) {
    this.latitudeNow = parseFloat(latitude) || 0;
    // this.initMap();
    this.setView();
  }
  get latitude() {
    return this.latitudeNow;
  }
  longitudeNow = 0;
  @Input()
  set longitude(longitude: any) {
    this.longitudeNow = parseFloat(longitude) || 0;
    // this.initMap();
    this.setView();
  }
  get longitude() {
    return this.longitudeNow;
  }
  @Output("onSelect") onSelect: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    this.initMap();
  }

  initMap() {
    let _this = this;
    // console.log(latitude, longitude);
    const latitude = this.latitudeNow;
    const longitude = this.longitudeNow;
    this.map = L.map("map", {
      center: [latitude, longitude],
      zoom: 17,
    });
    const tiles = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );
    tiles.addTo(this.map);

    const customIcon = L.icon({
      iconUrl: "assets/images/marker-icon.png",
      shadowUrl: "assets/images/marker-shadow.png",

      iconSize: [36, 36], // size of the icon
      shadowSize: [0, 0], // size of the shadow
      // iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
      // shadowAnchor: [0, 0], // the same for the shadow
      // popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
    });

    this.marker = L.marker([latitude, longitude], {
      draggable: _this.draggable,
      icon: customIcon,
    });
    this.marker.addTo(this.map);

    this.marker.on("dragend", (e) => {
      // console.log(e.target._latlng);
      // console.log(_this);
      const location = {
        lat: e.target._latlng.lat,
        lng: e.target._latlng.lng,
      };
      _this.doSelect(location);
    });
    this.map.on("click", (e) => {
      // console.log(e.target._latlng);
      // console.log(e);
      const location = {
        lat: e.latlng.lat,
        lng: e.latlng.lng,
      };
      _this.doSelect(location);
    });
  }

  setView() {
    if (this.map) {
      const position = {
        lat: this.latitudeNow.toString(),
        lng: this.longitudeNow.toString(),
      };
      this.marker.setLatLng(position);
      this.map.setView(position);
    }
  }
  doSelect(val) {
    this.onSelect.emit(val);
  }
}
