<div class="form-group">
    <div class="d-flex align-item-center">
        <!-- <label for="" *ngIf="label !== ''">{{label}}</label> -->
        <label *ngIf="label !== ''" class="lh15 pb-1">{{label}} <span class="text-danger"
                *ngIf="mandatory">*</span></label>
        <div class="ml-auto text-danger lh15 pb-1" *ngIf="value === '' && errorTrueNow">
            <small>mandatory</small>
        </div>
        <ng-container *ngIf="editOption === 'true'">
            <div class="ml-auto">
                <a (click)="edit()">Edit</a>
            </div>
        </ng-container>
        <ng-container *ngIf="errorText !== ''">
            <div class="ml-auto">
                <small class="text-danger pl-3">{{errorText}}</small>
            </div>
        </ng-container>
    </div>
    <textarea appNoEmoji class="form-control" [(ngModel)]="value" name="{{name}}" placeholder="{{placeholder}}"
        [ngClass]="{'border-warn': value === '' && errorTrueNow}" [disabled]="btnClickedNow" (blur)="blured()" #inputbox
        class="{{class}}" (keydown)="checkenter($event)" #selectInput></textarea>
</div>