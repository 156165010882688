import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AppConfig } from '../../config/app.config';

@Pipe({
  name: 'appCurrency'
})
export class AppCurrencyPipe implements PipeTransform {
  currencyCode = AppConfig.baseCurrency;
  constructor(
    private currencyPipe: CurrencyPipe
  ) {

  }
  transform(value: any, type?:'nosymbol'): any {
    if (!value) {
      return '';
    }
    if(type === 'nosymbol'){
      const xValue = this.currencyPipe.transform(value, this.currencyCode, 'symbol-narrow');
      const fValue = xValue.replace('VUV', '');
      return fValue;
    } else {
      const xValue = this.currencyPipe.transform(value, this.currencyCode, 'symbol-narrow');
    const fValue = xValue.replace('VUV', '') + ' (VUV)';
    return fValue;
    }
    

   
  }
}
