import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { format, isMatch, parse } from "date-fns";
import { AppSettingsService } from "../../services/app-settings/app-settings.service";
import { ValueAccessorDateBase } from "./value-accessor-date-base";

@Component({
  selector: "app-date-control",
  templateUrl: "./date-control.component.html",
  styleUrls: ["./date-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateControlComponent,
      multi: true,
    },
  ],
})
export class DateControlComponent
  extends ValueAccessorDateBase<string>
  implements OnInit, AfterViewInit {
  xvalue = {
    date: null,
    view: "",
  };
  minDateNow = "";
  maxDateNow = "";
  errorTrueNow = false;
  btnClickedNow = false;

  @Input() public label = "";
  @Input() public name = "";
  @Input() public placeholder = "eg. dd/mm/yyyy";
  @Input() public requiredtrue: string = "false";
  @Input() public errormsg = "";
  @Input() public mandatory = false;
  @Input() public link = false;
  @Input() public returnFormat = "";

  @Input()
  set minDate(minDate: string) {
    // this.minDateNow =
    //   moment(minDate, this.inputFormat).format("YYYY-MM-DD") || "";
  }
  get minDate() {
    return this.minDateNow;
  }

  // Set Max Date
  @Input()
  set maxDate(maxDate: string) {
    // this.maxDateNow =
    // moment(maxDate, this.inputFormat).format("YYYY-MM-DD") || "";
  }
  get maxDate() {
    return this.maxDateNow;
  }

  // Set errorTrue
  @Input()
  set errorTrue(errorTrue: boolean) {
    this.errorTrueNow = errorTrue || false;
  }
  get errorTrue() {
    return this.errorTrueNow;
  }

  // Set btnTrue
  @Input()
  set btnClicked(btnClicked: boolean) {
    this.btnClickedNow = btnClicked || false;
  }
  get btnClicked() {
    return this.btnClickedNow;
  }
  @ViewChild("inputbox", { static: false })
  inputbox!: ElementRef;

  constructor(public appSetting: AppSettingsService) {
    super();
  }
  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.getValue().subscribe((xvalue) => {
      // console.log(xvalue);
      if (xvalue) {
        let dateFormat = this.appSetting.environment.serverDateFormat;
        if (
          isMatch(
            this.value,
            this.appSetting.environment.serverDateFormatWithTime
          )
        ) {
          dateFormat = this.appSetting.environment.serverDateFormatWithTime;
        }
        this.xvalue.date = parse(this.value, dateFormat, new Date());
      }
    });
  }

  doFocus() {
    // if (this.xvalue.date !== "") {
    // const nowDate = moment(this.xvalue.date).format(this.entryFormat);
    // console.log(nowDate);
    // if (nowDate !== "Invalid date") {
    //   this.inputbox.nativeElement.value = nowDate;
    // }
    // }
  }
  checkDate(event: any) {
    // if (event.inputType === "deleteContentBackward") {
    //   return;
    // }
    // let xvalue = event.target.value.toString();
    // let rvalue = xvalue.replace(this.digitPattern, "");
    // if (rvalue.length > this.entryFormat.length) {
    //   rvalue = rvalue.substring(0, rvalue.length - 1);
    // }
    // // console.log(this.dateSinglePattern.test(rvalue));
    // this.dateSinglePattern.test(rvalue) ? (rvalue = "0" + rvalue) : "";
    // if (rvalue.length === 2 && !this.dateSinglePattern.test(rvalue)) {
    //   this.datePattern.test(rvalue)
    //     ? (rvalue = rvalue + this.seperator)
    //     : (rvalue = rvalue.substring(0, rvalue.length - 1));
    // }
    // if (rvalue.length === 4) {
    //   /^(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])\/\/$/.test(rvalue)
    //     ? (rvalue = rvalue.substring(0, rvalue.length - 1))
    //     : "";
    // }
    // if (this.monthSinglePattern.test(rvalue)) {
    //   rvalue = rvalue.slice(0, 3) + "0" + rvalue.slice(3);
    // }
    // if (rvalue.length === 5 && !this.monthSinglePattern.test(rvalue)) {
    //   this.monthPattern.test(rvalue)
    //     ? (rvalue = rvalue + this.seperator)
    //     : (rvalue = rvalue.substring(0, rvalue.length - 1));
    // }
    // if (rvalue.length === 7) {
    //   /^(?:[1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(?:[1-9]|0[1-9]|1[0-2])\/\/$/.test(
    //     rvalue
    //   )
    //     ? (rvalue = rvalue.substring(0, rvalue.length - 1))
    //     : "";
    // }
    // if (rvalue.length > 5) {
    //   // console.log(this.yearPattern.test(rvalue));
    // }
    // event.target.value = rvalue;
    // this.dateSelected();
  }
  dateSelected() {
    // console.log(this.xvalue.date);
    const returnFormat =
      this.returnFormat === ""
        ? this.appSetting.environment.serverDateFormat
        : this.returnFormat;
    const xdate = format(this.xvalue.date, returnFormat);
    // moment(this.xvalue.date).format(this.returnFormat);
    // console.log(this.xvalue.date, xdate);
    if (xdate === "Invalid date") {
      this.value = "";
    } else {
      this.value = xdate;
    }
  }
  doClear() {
    this.value = "";
    this.xvalue.date = "";
  }
}
